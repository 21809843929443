import React from "react";
import { Title, Meta, Project } from "../../components/projects/Project";
import PaginateProject from "../../components/PaginateProject";

import {
  Box,
  Flex,
  useColorModeValue,
  Container,
  Text,
  Link,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

function Neoquizz() {
  const { t } = useTranslation();

  return (
    <Container maxW="800px" p={4}>
      <Title>{t("neoquizz.title")}</Title>
      <Project>
        <Text mb={4}>{t("neoquizz.description")}</Text>
        <Flex direction="column" gap={4}>
          <Flex
            direction={{ base: "column", md: "row" }}
            gap={{ base: "0", md: "5" }}
            alignItems={{ base: "flex-start", md: "center" }}
          >
            <Meta>Stack</Meta>
            <Flex wrap="wrap" gap={2}>
              <Text>React & TailwindCss</Text>
              <Text>|</Text>
              <Text>Python & Flask API</Text>
              <Text>|</Text>
              <Text>DialogFlow</Text>
              <Text>|</Text>
              <Text>OpenAI</Text>
              <Text>|</Text>
              <Text>Scaleway Bucket</Text>
            </Flex>
          </Flex>
          <Flex
            direction={{ base: "column", md: "row" }}
            gap={{ base: "0", md: "5" }}
            alignItems={{ base: "flex-start", md: "center" }}
          >
            <Meta>{t("neoquizz.others.title")}</Meta>
            <Flex wrap="wrap" gap={2}>
              <Text>Responsive</Text>
            </Flex>
          </Flex>
          <Flex
            direction={{ base: "column", md: "row" }}
            gap={{ base: "0", md: "5" }}
            alignItems={{ base: "flex-start", md: "center" }}
          >
            <Meta>{t("neoquizz.link.title")}</Meta>
            <Link
              href="https://quizz-mind-front.vercel.app/"
              color={useColorModeValue("purple", "orange")}
              isExternal
            >
              https://quizz-mind-front.vercel.app/
            </Link>
          </Flex>
        </Flex>
        <Box mb={5}>
          <video controls>
            <source src="../neoquizz-2.mp4" type="video/mp4" />
          </video>
          <video controls>
            <source src="../neoquizz-1.mp4" type="video/mp4" />
          </video>
        </Box>
      </Project>
      <PaginateProject
        title={t("portfolio.title")}
        href="/projects/portfolio"
        projectLogo="../logo-small.png"
        isLeft={false}
      />
    </Container>
  );
}

export default Neoquizz;
