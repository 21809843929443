import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Text,
  Image,
  Flex,
  useColorModeValue,
  LinkBox,
} from "@chakra-ui/react";

type Props = {
  title: string;
  href: string;
  thumbnail: string;
  logoProject: string;
  id: string;
};

const ProjectCard: React.FC<Props> = ({
  title,
  href,
  thumbnail,
  id,
  logoProject,
}) => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseMove = (e: React.MouseEvent) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    setMousePosition({ x, y });
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const bg = useColorModeValue("rgba(250, 247, 247, 0.2)", "rgba(47,52,62)");

  return (
    <Box
      maxW={{ base: "90%", sm: "80%", md: "sm" }}
      w="100%"
      textAlign="center"
      bg={bg}
      pt={3}
      px={3}
      borderRadius={5}
      m="auto"
      position="relative"
      onMouseMove={handleMouseMove}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      _hover={{
        transform: "scale(1.01)",
        transition: "0.7s",
      }}
      sx={
        isHovered
          ? {
              "&::after": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                borderRadius: "5px",
                pointerEvents: "none",
                background: `radial-gradient(
                  circle at ${mousePosition.x}px ${mousePosition.y}px,
                  rgba(100, 110, 255, 0.5),
                  transparent 80%
                )`,
              },
            }
          : {}
      }
    >
      <LinkBox cursor="pointer">
        <Link to={href}>
          <Image
            src={thumbnail}
            alt={title}
            borderRadius="md"
            width="100%" // Ensures the image fills the container
            height="200px" // Set a fixed height to make all images uniform
          />
          <Flex
            justifyContent="space-between"
            alignItems="center"
            gap={4}
            mt={2}
            mb={3}
          >
            <Image
              borderRadius="full"
              src={logoProject}
              alt={logoProject}
              width="30px"
              height="30px"
            />
            <Box flex="1" textAlign="left">
              <Text fontSize={{ base: "12px", md: "16px" }}>{title}</Text>
            </Box>
          </Flex>
        </Link>
      </LinkBox>
    </Box>
  );
};

export default ProjectCard;
