import { Box } from "@chakra-ui/react";
import styled from "@emotion/styled";

export const BioSection = styled(Box)`
  text-align: center;
`;

export const BioYear = styled.span`
  opacity: 0.5;
`;
